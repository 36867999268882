import { formatPathString } from "../../../../../util/util"

export function formatBlogDetails(props) {
  const blogImage = props.blogHeroImage?.data?.attributes ? props.blogHeroImage?.data?.attributes : props.blogHeroImage
  let selectedRelatedBlogs = props.linkedBlogs?.data ? props.linkedBlogs?.data : props.linkedBlogs
  const blogHeroImage = blogImage
  selectedRelatedBlogs = selectedRelatedBlogs?.filter(blog => blog?.environment?.includes(process.env.GATSBY_ENV))
  let linkedBlogs = selectedRelatedBlogs?.map(article => {
    const childBlog = article?.attributes ? article?.attributes : article
    const articleImage = childBlog?.blogHeroImage ? childBlog?.blogHeroImage : article?.blogHeroImage
    return {
      ...childBlog,
      id: article.id,
      blogHeroImage: articleImage,

    }
  })
  const blogContent = props.blogContent?.map(article => {
    return { ...article, strapi_component: article?.__component }
  })
  const blog = {
    id: props.id,
    blogTitle: props.blogTitle,
    blogSubtitle: props.blogSubtitle,
    publishedDate: props.publishedDate,
    publication: props.publication,
    seoDescription: props.seoDescription,
    seoTitle: props.seoTitle,
    environment: props.environment,
    likeCount: props.likeCount,
    countIds: props.countIds,
    documentId: props.documentId,
    image: {
      url: blogHeroImage?.url,
      caption: blogHeroImage?.caption
    },
  }
  const relatedBlogs = formatLinkedBlogs(linkedBlogs);
  const contentBody = blogContent?.map(a => {
    return {
      strapi_component: a.strapi_component,
      sectionBody: getSectionByComponentName(a.strapi_component, a)
    }
  })
  return { ...blog, relatedBlogs: relatedBlogs ?? [], blogBody: contentBody ?? [] }
}
export function formatLinkedBlogs(arr) {
  return arr?.map(a => {
    const blogImage = a.blogHeroImage
    let formatedPath = formatPathString(a?.blogTitle);
    return {
      id: a.id,
      blogUrl: `/blog/${formatedPath}/`,
      seoDescription: a.seoDescription,
      seoTitle: a.seoTitle,
      blogTitle: a.blogTitle,
      blogSubtitle: a.blogSubtitle,
      publication: a.publication,
      publishedDate: a.publishedDate,
      image: blogImage ? {
        url: blogImage?.url
      } : null
    }
  })
}


function getSectionByComponentName(name, componentValue) {
  switch (name) {
    case "section.blog-video-block":
      return componentValue.videoMedia
    case "section.blog-rich-editor-block":
      return componentValue.sectionBody
    case "section.blog-markdown-editor":
      return componentValue?.markdownBody?.data?.markdownBody

    default:
      return componentValue?.sectionBody?.data?.sectionBody
  }
}